class Epm {

  init() {
    $('.events.epm').on('click', '#people_import_button', epm.importButtonClick);
    this.importModal = $("#import_users_modal");
    this.modalClose = $('#import_users_modal_close');
    this.eventsSection = $('#eventSearchWrapper');
    this.peopleSection = $('#userSearchWrapper');
    this.peopleSearchField = $('#userSearchField');
    this.inviteLinkSection = $('#inviteLinkWrapper');
    this.eventsSelectButton = $('#eventSelectButton');
    this.peopleSearchButton = $('#user-search-button');
    this.eventsSelect = $('#eventSelect');
    this.peopleResultsBody = $('#people-results-body');
    this.resultsTableWrapper = $('#results-table-container');
    this.importPeopleButton = $('#import-people-button');
    this.resultsLabel = $('#results-label');
    this.eventId = $('#event_attributes').data("event-id");

    this.inviteLinkDepartmentsSelect = $('#link_department_ref_id');
    this.inviteLinkCredentialsSelect = $('#link_credential_type_ref_id');
    this.inviteLinkInput = $('#link_invite_input');
    this.inviteLinksResetButton = $('#links_reset_button');

    this.inviteLinkDepartmentsSelect.on('change', epm.onInviteLinkSelectChange);
    this.inviteLinkCredentialsSelect.on('change', epm.onInviteLinkSelectChange);
    this.inviteLinksResetButton.on('click', epm.onInviteLinksResetClick);

    this.modalClose.on('click', epm.importModalClose);
    this.importPeopleButton.on('click', epm.importPeople);
    this.eventsSelectButton.on('click', epm.getPeopleFromEvent);
    this.peopleSearchButton.on('click', epm.searchPeople);
    this.peopleSearchField.on('keyup', function (e) {
      if (e.key === 'Enter' || e.keyCode === 13) {
        epm.peopleSearchButton.click();
      }
    });
    this.initViews();
    this.selectedImportEvent = null;

    this.importPeopleButton.hide();
    this.resultsLabel.hide();
    this.searchResults = [];
    this.modeisEvent = true;

    $('.events.epm').on('change', '.credentialOctagonAccessCb', this.onCredentialOctagonAccessCbChange);
    $('.events.epm').on('click', '.personDeleteButton', this.onPersonDeleteButtonClick);
  };

  onCredentialOctagonAccessCbChange() {
    let event_person_id = $(this).data('event-person-id');
    let octagon_access_checked = $(this).prop('checked');
    $.ajax({
      url: '/event_people/' + event_person_id + '/ajax_update_credential_octagon_access',
      data: { credential_octagon_access: octagon_access_checked },
      method: 'POST',
      beforeSend: function (xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      success: function(data){
        if (data['success']){
          toastr.success('Credential Octagon Access Updated.');
        } else {
          toastr.error('Unable To Update Credential Octagon Access.');
        };
      },
      error: function(data){
        toastr.error('Unable To Update Credential Octagon Access.');
      }
    });
  };

  onInviteLinkSelectChange() {
    if (epm.inviteLinkDepartmentsSelect.val() && epm.inviteLinkCredentialsSelect.val()) {
      epm.inviteLinkInput.val('');
      $.ajax({
        url: '/events/' + epm.eventId + '/ajax_generate_media_user_event_link',
        method: 'POST',
        data: {department_ref_id: epm.inviteLinkDepartmentsSelect.val(), credential_type_ref_id: epm.inviteLinkCredentialsSelect.val()},
        beforeSend: function (xhr) {
          xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
        },
        success: function(data){
          if (data['success']){
            epm.inviteLinkInput.val(data['link']);
          } else {
            toastr.error('Unable To Generate Link.');
          };
        },
        error: function(data){
          toastr.error('Unable To Generate Link.');
        }
      });
    }
  };

  onInviteLinksResetClick() {
    if (confirm('Are You Sure? You will have to share new links as they will stop working')) {
      epm.inviteLinkInput.val('');
      $.ajax({
        url: '/events/' + epm.eventId + '/ajax_delete_media_user_links',
        method: 'DELETE',
        beforeSend: function (xhr) {
          xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
        },
        success: function(data){
          if (data['success']){
            epm.inviteLinkInput.val('');
            epm.inviteLinkDepartmentsSelect.val(null);
            epm.inviteLinkCredentialsSelect.val(null);
            toastr.success('All Links Have Been Reset.')
          } else {
            toastr.error('Unable To Reset Links.');
          };
        },
        error: function(data){
          toastr.error('Unable To Reset Links.');
        }
      });
    }
  };

  onPersonDeleteButtonClick() {
    if (confirm('Are You Sure?')) {
      $.ajax({
        url: '/event_people/' + $(this).data('event-person-id') + '/ajax_delete_event_person',
        method: 'POST',
        beforeSend: function (xhr) {
          xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
        },
        success: function(data){
          if (data['success']){
            toastr.success('Person Removed From EPM - Pending Approval.');
            setTimeout(function () {
              location.reload();
            }, 500);
          } else {
            toastr.error('Unable To Remove Person From EPM.');
          };
        },
        error: function(data){
          toastr.error('Unable To Remove Person From EPM.');
        }
      });
    }
  };

  importButtonClick() {
    epm.clearResults();
    epm.importModal.removeClass('modal__hidden');
    epm.importModal.addClass('modal__visible');
  }

  importModalClose() {
    epm.importModal.removeClass('modal__visible');
    epm.importModal.addClass('modal__hidden');
    epm.clearResults();
  }

  getPeopleFromEvent() {
    epm.clearResults();
    let eventHubId = epm.eventsSelect.val();
    if (!eventHubId) {
      toastr.error('Please Select An Event.');
      return;
    }
    let $this = $(this);
    epm.selectedImportEvent = eventHubId;
    $this.text('Searching...');
    $.ajax({
      type: 'GET',
      url: '/events/' + epm.eventId + '/ajax_get_people_from_event/' + eventHubId,
      beforeSend: function (xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      success: function (data, textStatus, jqXHR) {
        $this.text('Search');
        epm.populateResultsTable(data, true);
      },
      error: function (jqXHR, textStatus, errorThrown) {
        $this.text('Search');
        toastr.error('Cannot Search At The Moment. Please Try Again.');
      }
    });

  }

  searchPeople() {
    epm.clearResults();
    let filter = epm.peopleSearchField.val();
    if (!filter) {
      toastr.error('Please Enter A Search Term.');
      return;
    }
    let $this = $(this);
    $this.text('Searching...');
    $.ajax({
      type: 'GET',
      url: '/events/' + epm.eventId + '/ajax_person_search?filter=' + filter,
      beforeSend: function (xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      success: function (data, textStatus, jqXHR) {
        $this.text('Search');
        epm.populateResultsTable(data, false);
      },
      error: function (jqXHR, textStatus, errorThrown) {
        $this.text('Search');
        toastr.error('Cannot Search At The Moment. Please Try Again.');
      }
    });

  }

  importPeople() {
    if (epm.importPeopleButton.text() === 'Importing...') {
      return;
    }
    epm.importPeopleButton.text('Importing...');
    let people = [];
    let checkedPeople = $('#people-results-body').find('input[type="checkbox"]:checked');
    if (!checkedPeople) {
      return;
    }
    checkedPeople.each(function () {
      people.push({id: $(this).data('person-id'), department_hub_id: $(this).data('department-hub-id'), role_hub_id: $(this).data('role-hub-id'), credential_type_hub_id: $(this).data('credential-type-hub-id')});
    })
    let json = {
      people
    }
    let url = '/events/' + epm.eventId + '/ajax_import_people';
    $.ajax({
      type: 'POST',
      url: url,
      contentType: "application/json; charset=utf-8",
      data: JSON.stringify(json),
      beforeSend: function (xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      success: function (data, textStatus, jqXHR) {
        toastr.success('People Imported.');
        setTimeout(function () {
          location.reload();
        }, 200);
      },
      error: function (jqXHR, textStatus, errorThrown) {
        epm.importPeopleButton.text('Import Selected');
        toastr.error('Could not Import');
      }
    });
  }

  initViews() {
    $('input[type=radio][name=searchType]').on('change', function () {
      epm.clearResults();

      switch ($(this).val()) {
        case 'event':
          epm.eventsSection.show();
          epm.peopleSection.hide();
          epm.inviteLinkSection.hide();
          epm.modeisEvent = true;
          break;
        case 'user':
          epm.peopleSection.show();
          epm.eventsSection.hide();
          epm.inviteLinkSection.hide();
          epm.modeisEvent = false;
          break;
        case 'link':
          epm.inviteLinkSection.show();
          epm.eventsSection.hide();
          epm.peopleSection.hide();
          break;
      }
    });
  }

  clearResults() {
    epm.peopleResultsBody.html('');
    epm.resultsTableWrapper.html('');
    epm.importPeopleButton.hide();
  }

  populateResultsTable(data, eventsMode) {
    epm.resultsLabel.show();

    if (!data || data.length == 0) {
      epm.populateEmptyResult(false);
      return;
    }
    epm.searchResults = data;
    if (eventsMode) {
      epm.populateTableHeaderForEventsSearch();
    } else {
      epm.populateTableHeaderForPeopleSearch();
    }
    data.forEach(person => {
      let fullName = `${person.first_name} ${person.last_name}`;
      let tableRow = '';
      if (eventsMode) {
        tableRow = `<tr class="event-person-table-row">
              <th scope="row"><input type="checkbox" class="selectedPersons" data-person-id="${person.id}" data-department-hub-id="${person.department}" data-role-hub-id="${person.role}" data-credential-type-hub-id="${person.credential_type}" name="userSelect"/></th>
              <td>${fullName}</td>
              <td>${person.department_name}</td>
              <td>${person.role_name}</td>
              <td>${person.credential_type_name}</td>
            </tr>`;
      } else {
        tableRow = `<tr class="event-person-table-row">
              <th scope="row"><input type="checkbox" class="selectedPersons" data-person-id="${person.id}" data-department-hub-id="" data-role-hub-id="" data-credential-type-hub-id="${person.credential_type}" name="userSelect"/></th>
              <td>${fullName}</td>
              <td>${person.email1}</td>
              <td>${person.phone1}</td>
              <td>${person.credential_type_name}</td>
            </tr>`;
      }
      $('#people-results-body').append(tableRow);
      $("#selectAll").click(function () {
        $('.selectedPersons').not(this).prop('checked', this.checked);
      });
      epm.importPeopleButton.show();

    });

  }

  populateTableHeaderForEventsSearch() {
    let table = `<div style="margin-bottom: 10px;"><i>Click Heading To Sort</i></div><table id="importUsersTable" class="standardTable">
            <caption><span class="visuallyHidden"></span></caption>
            <thead>
            <tr>
              <th scope="col"><input type="checkbox" id="selectAll" class="selectAll"  name="selectAll"/>
              </th>
              <th scope="col" class="sortable">Name</th>
              <th scope="col" class="sortable">Department</th>
              <th scope="col" class="sortable">Role</th>
              <th scope="col" class="sortable">Credential Type</th>
            </tr>
            </thead>
            <tbody id="people-results-body">

            </tbody>
          </table>
      <a id="personCreateButton" class="button buttonSmall buttonWhite" style="width:200px;margin-top:10px;" href="javascript:void(0)">Not Found? Create New Person</a>`;
    epm.resultsTableWrapper.html(table);
  }

  populateTableHeaderForPeopleSearch() {
    let table = `<div style="margin-bottom: 10px;"><i>Click Heading To Sort</i></div><table id="importUsersTable" class="standardTable">
            <caption><span class="visuallyHidden"></span></caption>
            <thead>
            <tr>
              <th scope="col"><input type="checkbox" id="selectAll" class="selectAll"  name="selectAll"/>
              </th>
              <th scope="col" class="sortable">Name</th>
              <th scope="col" class="sortable">Email</th>
              <th scope="col" class="sortable">Phone Number</th>
              <th scope="col" class="sortable">Credential Type</th>
            </tr>
            </thead>
            <tbody id="people-results-body">

            </tbody>
          </table>
      <a id="personCreateButton" class="button buttonSmall buttonWhite" style="width:200px;margin-top:10px;" href="javascript:void(0)">Not Found? Create New Person</a>`;
    epm.resultsTableWrapper.html(table);
  }

  populateEmptyResult() {
    epm.resultsTableWrapper.html('<div>No results round. Please keep searching or create a new person below if you are sure this person doesn\'t exist.</div><div style="margin-top: 20px;"><a id="personCreateButton" class="button buttonSmall buttonWhite" style="width:200px;" href="javascript:void(0)">Create New Person</a></div>');
  }

}

export let epm = new Epm();
